import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
// import LoadingOverlay from 'react-loading-overlay';


ReactDOM.render(


  <React.StrictMode>
    {/* <LoadingOverlay
        active={true}
        fadeSpeed = {1000}
        spinner
        text='正在加載您的內容.....'
        > */}
             <App />
     {/* </LoadingOverlay> */}

  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
